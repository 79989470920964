<template>
  <section class="h-100">
    <div class="container-fluid h-100">
      <div class="row align-items-center h-100">
        <div class="d-flex flex-column justify-content-center col-lg-6 col-12 h-100" id="login-form">  
          <div class="row">
            <center><img src="../assets/menu/house.png" alt="House" class="house-img"></center>
          </div>
          <div class="form">
            <!-- USERNAME -->
            <div class="row py-2">
              <div class="col-12">
                <input type="text" class="form-control" placeholder="E-mail" v-model="username" :class="{ 'is-invalid': submitted && hasError('Username') }">
                <div v-if="submitted && hasError('Username')" class="invalid-feedback">
                  <span v-if="!$v.username.required">É necessário o E-mail</span>
                  <span v-if="!$v.username.email">Email Inválido</span>
                  <span v-if="errors.Username">{{errors.Username}}</span>
                </div>
              </div>
            </div>
            <!-- PASSWORD -->
            <div v-if="mode !== 'REQUEST-LINK'" class="row py-2">
              <div class="col-12" >
                <input type="password" class="form-control" placeholder="Palavra-passe" v-model="password" :class="{ 'is-invalid': submitted && hasError('Password') }">
                <div v-if="submitted && hasError('Password')" class="invalid-feedback">
                  <span v-if="!$v.password.required">É necessário a Password</span>
                  <span v-if="errors.Password">{{errors.Password}}</span>
                </div>
                <!-- Description of forgot password -->
                <p v-if="mode == 'REQUEST-LINK'">Um e-mail com um link para definir uma senha será enviado para o seu e-mail.</p>
              </div>
            </div>
            <!-- Confirm pass -->
            <div v-if="mode == 'RECOVER-PASSWORD'" class="col-12 form-group my-2">
              <input type="password" placeholder="Palavra-passe confirmação" v-model="confirmPassword" id="confirmPassword" name="confirmPassword" class="form-control" :class="{ 'is-invalid': submitted && hasError('ConfirmPassword') }" />
              <div v-if="submitted && hasError('ConfirmPassword')" class="invalid-feedback">
                  <span v-if="!$v.confirmPassword.required">É necessário confirmar a Password</span>
                  <span v-else-if="!$v.confirmPassword.sameAsPassword">As Passwords devem corresponder</span>
              </div>
            </div>
              <!-- token -->
              <div class="row py-2" v-if="mode == 'RECOVER-PASSWORD'">
                <div class="col-12">
                  <input type="number" placeholder="Token" class="form-control" v-model="token" :class="{ 'is-invalid': submitted && hasTokenError }">
                  <div v-if="submitted && hasTokenError" class="invalid-feedback">
                    <span v-if="!$v.token.required">É necessário o Token</span>
                    <span v-if="errors.InvalidToken">{{errors.InvalidToken}}</span>
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-end col-12 mt-3">
                <a class="primary-text-color" v-if="mode == 'LOGIN'" @click="mode = !requested ? 'REQUEST-LINK' : 'RECOVER-PASSWORD'">Esqueci-me da palavra-passe</a>
                <a class="primary-text-color" v-else @click="mode = 'LOGIN'">Voltar</a>
              </div>
              <div class="row mt-5 mb-5">
                <center>  
                  <div v-if="loading" class="spinner-border" role="status"></div>
                  <div v-else class="col-12">
                      <button v-if="mode == 'LOGIN'" class="btn button-login text-light" type="submit" @click="loginClicked">Login</button>
                      <button v-else-if="mode == 'REQUEST-LINK'" class="btn button-login text-light" type="submit" @click="sendEmailClicked">Enviar E-mail</button>
                      <button v-else class="btn button-login text-light" type="submit" @click="changePasswordClicked">Alterar Password</button>
                  </div>
                </center>
              </div>
          </div>
        </div>
        <img src="../assets/login/imgLogin.jpg" alt="login-img" class="login-img img-fluid col-lg-6 d-none d-lg-block p-0">
      </div>          
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { required, sameAs, email } from 'vuelidate/lib/validators'
import { UTILS_HELPER } from "@/helpers/utils.js";
import EventBus from '@/events/bus.js';

export default {
  data () {
    return {
      username: '',
      password: '',
      confirmPassword: null,
      token: '',
      loginError: false,
      requested: false,
      submitted: false,
      loading: false,
      mode: "LOGIN"
    }
  },
  validations() {
    switch(this.mode) 
    {
      case 'LOGIN':
      {
        return {
          username: { required, email },
          password: { required }
        }
      }
      case 'REQUEST-LINK':
      {
        return {
          username: { required, email }
        }
      }
      case 'RECOVER-PASSWORD':
      {
        return {
          username: { required, email },
          password: { required },
          confirmPassword: { required, sameAsPassword: sameAs('password') },
          token: { required }
        }
      }
    } 
  },
  computed:
  {
    ...mapState({ 
      alertType: state => state.alert.type,
      alertOutput: state => state.alert.output
    }),
    ...mapState('account', ['processTask']),
    errors: function()
    {
      return this.alertOutput && this.alertOutput.Errors ? this.alertOutput.Errors : {};   
    },
    hasTokenError()
    {
      return this.$v[UTILS_HELPER.convertToCamelCase("Token")].$error || Object.prototype.hasOwnProperty.call(this.errors, "InvalidToken")
    }
  },
  name: 'Login',
  methods: {
    ...mapActions('account', ['login']),
    ...mapActions('account', ['requestLinkPassword']),
    ...mapActions('account', ['resetPassword']),
    ...mapActions({
        clearAlert: 'alert/clear' 
    }),
    loginClicked: function(){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.loading = true;
      const { username, password } = this;
      this.login({  username, password })
    },
    sendEmailClicked: function()
    {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      if(this.username)
      {
        this.loading = true;
        this.requested = true;
        this.requestLinkPassword(this.username);
      }
    },
    changePasswordClicked: function()
    {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      const { username, password, token } = this;
      if(username && password && token)
      {
        this.loading = true;
        this.resetPassword({username, password, token});
      }
    },
    hasError(fieldName)
    {
      return this.$v[UTILS_HELPER.convertToCamelCase(fieldName)].$error || Object.prototype.hasOwnProperty.call(this.errors, fieldName);
    },
  },
  created: function()
  {
    this.clearAlert();
  },
  watch: 
  { 
    alertType: function()
    {
      this.loginError = this.alertType === "alert-danger";
    },
    alertOutput: function()
    {
      this.loginError = this.alertType === "alert-danger";
    },
    username: function()
    {
      this.loginError = false;
    },
    password: function()
    {
      this.loginError = false;
    },
    loginError: function(val)
    {
      if(val)
      {
        this.loading = false;
      }
    },
    mode() {
      this.submitted = false;
    },
    processTask: function(val)
    {
      var result;
      if(val != null)
      {
        if(this.mode == 'REQUEST-LINK')
        {
          result = "Erro no pedido do link!";

          if(val == true)
          {
            result = EventBus.$emit("success-alert-requested", { message: 'Sucesso no pedido do link! Coloque o token que foi enviado para o seu email.', primaryButton: 'Ok'});
            this.mode = 'RECOVER-PASSWORD';
          }

          this.loading = false;
        }
        else if(this.mode == 'RECOVER-PASSWORD')
        {
          if(val == false)
          {
            result = "Erro a recuperar Password!";
            this.loading = false;
          }
          else
          {
            result = "Password recuperada com Sucesso!";
            this.mode = 'LOGIN';
            this.loginClicked();
          }
        }

        return result;
      }
    }
  }
}

</script>

<style scoped>
/* Structure */
.house-img
{
  margin-bottom: 5rem;
  width: 10em;
}

.login-img
{
  height: 100vh;
  object-fit: cover;
  border-radius: 50px 0 0 50px;
}

@media only screen and (max-height: 599px) {
  .login-img
  {
    height: 100%;
  }
}

#login-form{
  padding: 0 5rem;
}

.button-login{
  background-color: rgb(78, 84, 200) ;
  font-weight: bold;
  border-radius: 10px;
}
</style>